<template>
    <UserManagmentModal
        :value="value"
        :headline="$t('classMgmt.editAssignmentHeader')"
        :subHeadline="userCountOrName(selectedStudents,$tc('classMgmt.students', selectedStudents.length))"
        size="600"
        @closeDialogs="closeDialogs()"
    > 
        <v-card-text>
            <v-alert
                :value="previousAssignment(selectedStudents,1)"
                color="info"
                icon="info"
                outline
                class="mb-3"
            >
                {{$t('classMgmt.previousAssignment')}} {{previousAssignment(selectedStudents,1)}}
            </v-alert>
            <v-alert
                :value="hasGradeLevelLessThan(selectedStudents,3)"
                color="warning"
                icon="warning"
                outline
                class="mb-3"
            >
                {{$t('classMgmt.reflexNotRecommended')}}
            </v-alert>
            <v-select
                v-model="editAssignmentSelection"
                :items="assignmentIDs"
                item-text="label"
                item-value="id"
                :label="$tc('classMgmt.assignment', localizationConstants.SINGLE)"
                prepend-icon="assignment"
            ></v-select>
            <SeatCapAlert
                @updateButtonState="this.updateButtonState"
                :determineAssignment="determineAssignment"
                :modalSpecificSelection="editAssignmentSelection"
                :productID="1"
                :seatInfo="seatInfo"
                :selectedSchool="selectedSchool"
                :selectedStudents="selectedStudents"
            ></SeatCapAlert>
            <v-alert
                :value="editAssignmentAlert && ((hasEnoughSeats && editAssignmentSelection>0) || editAssignmentSelection===0)"
                color="warning"
                icon="warning"
                outline
            >
                <p v-if="selectedStudents.length>1">{{$t('classMgmt.changingMultipleStudentAssignments')}}</p>
                <p v-if="editAssignmentSelection==0 && (selectedStudents.length>1 || determineAssignment(selectedStudents[0],1).code!=0)">{{$t('classMgmt.settingNoAssignmentForStudents')}}</p>
                <div v-else-if="selectedStudents.length>1 && editAssignmentSelection>0">
                    <p>{{$t('classMgmt.youMightBeAboutToChange')}}</p>
                    <p>{{$t('classMgmt.ifSoTheIndividualReports')}}</p>
                </div>
                <div v-else-if="selectedStudents.length==1 && editAssignmentSelection>0 && determineAssignment(selectedStudents[0],1).code>0 && differentAssignmentFamilies(determineAssignment(selectedStudents[0],1).code,editAssignmentSelection)">
                    <p>{{$t('classMgmt.youAreAboutToChange')}} "{{getAssignmentFamily(determineAssignment(selectedStudents[0],1).code)}}" {{$t('classMgmt.to')}} "{{getAssignmentFamily(editAssignmentSelection)}}".</p>
                    <p>{{$t('classMgmt.thisStudents')}} "{{getAssignmentFamily(determineAssignment(selectedStudents[0],1).code)}}" {{$t('classMgmt.reportsStillAvailable')}}</p>
                    <p>{{$t('classMgmt.ifStudentHasPrior')}} "{{getAssignmentFamily(editAssignmentSelection)}}" {{$t('classMgmt.dataWillBeErased')}}</p>
                </div>
                <v-checkbox
                    v-model="editAssignmentUnderstand"
                    color="warning"
                >
                    <template v-slot:label>
                        <span class="warning--text body-1">{{$t('classMgmt.iUnderstand')}}</span>
                    </template>
                </v-checkbox>
            </v-alert>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" 
                @click="updateAssignments(selectedStudents,editAssignmentSelection)" 
                :disabled="loading || editAssignmentSelection===null || (editAssignmentAlert && !editAssignmentUnderstand) || (!hasEnoughSeats && editAssignmentSelection>0)" 
                class="elevation-0">
                <v-icon v-show="!loading">check</v-icon>
                <v-icon v-show="loading">fas fa-circle-notch fa-spin</v-icon>
                &nbsp;{{$t('classMgmt.accept')}}
            </v-btn>
        </v-card-actions>
    </UserManagmentModal>
</template>

<script>
    import Constants from 'mixins/Constants'
    import UserManagmentModal from 'components/layout/UserManagmentModal'
    import SeatCapAlert from 'components/modals/SeatCapAlert'
    import SubscriptionHelper from 'mixins/SubscriptionHelper'
    import { mapActions } from 'vuex'

	export default {
        name: 'EditAssignmentModal',
        components: { UserManagmentModal, SeatCapAlert },
        mixins: [Constants, SubscriptionHelper],
        props: {
            determineAssignment: Function,
            hasGradeLevelLessThan: Function,
            institutionID: Number,
            seatInfo: Function,
            selectedSchool: Object,
            selectedUsers: Array,
            subscriptionId: Number,
            tenantID: Number,
            userCountOrName: Function,
            value: Boolean,
        },
        data() {
            return {
                deleteStudents: false,
                editAssignmentSelection: null,
                editAssignmentUnderstand: false,
                selectedStudents: [{}],
                hasEnoughSeats: true,
                loading: false
            }
        },
		computed : {
            editAssignmentAlert(){
                return (this.selectedStudents.length>1) ||
                    (this.editAssignmentSelection==0 && (this.selectedStudents.length>1 || (this.selectedStudents[0] && this.determineAssignment(this.selectedStudents[0],1).code !=0))) ||
                    (this.selectedStudents.length==1 && this.editAssignmentSelection>0 && this.selectedStudents[0] && this.determineAssignment(this.selectedStudents[0],1).code>0 && this.differentAssignmentFamilies(this.determineAssignment(this.selectedStudents[0],1).code,this.editAssignmentSelection))
            },
            localizationConstants() {
                return {
                    NONE: 0,
                    SINGLE: 1,
                    MULTIPLE: 2
                }
            },
            platform(){ 
                 return this.$store.state.platform
            },
        },
        methods: {
            ...mapActions('platformData/students', ['updateStudents']),
            closeDialogs() {
                this.$emit('closeDialogs', ['editAssignmentModal'])
            },
            previousAssignment(students,productID){
                if(students.length==1){
                    if(this.determineAssignment(students[0],productID).code){
                        return null
                    } else {
                        if(students[0].reflexJson){ 
                            let prevID = parseInt(students[0].reflexJson.AssignmentID)
                            if(prevID){
                                return this.getAssignmentLabel(prevID)
                            } else {
                                return null
                            }
                        } else {
                            return null
                        }
                    }
                } else {
                    return null
                }
            },
            async updateAssignments(students,AssignmentID){
                this.loading = true
                let toRemoveFromProduct = []
                let toAddToProduct = []
                let toBeUpdated = []
                students.forEach(function(student){
                    if(AssignmentID){
                        student.reflexJson = JSON.stringify({AssignmentID:String(AssignmentID)})
                        toBeUpdated.push(student)
                        if(!student.licenses.filter(l => l.matchingLicense).map(l => l.productId).includes(1)){
                            toAddToProduct.push(student.id)
                        }
                    } else {
                        toRemoveFromProduct.push(student.id)
                    }
                })
                let institutionId = this.institutionID
                let tenantId = this.tenantID
                let subscriptionIds = [this.getActiveInstitutionSubscriptions(1, this.selectedSchool)[0].id]
                let self = this;
                if(toRemoveFromProduct.length){
                    await self.platform.removeUsersFromSubscription({tenantId, institutionId, subscriptionIds, loginIds: toRemoveFromProduct})
                }
                if(toAddToProduct.length){
                    await self.platform.addUsersToSubscription({tenantId, institutionId, subscriptionIds, loginIds: toAddToProduct})
                }
                if(toBeUpdated.length){
                    await self.updateStudents({institutionId, tenantId, students})
                }
                await self.$emit('updateModel')
                self.closeDialogs()
                this.loading = false
            },
            updateButtonState(hasEnoughSeats){
                this.hasEnoughSeats = hasEnoughSeats
            },
        },
        watch: {
            // If a user changes assignment after clicking that they understand makes them click it again
            editAssignmentSelection : function(){
                this.editAssignmentUnderstand = false
            },
            value(val){
                if (val){
                    this.editAssignmentUnderstand = false
                    this.selectedStudents = this.selectedUsers
                    if(this.selectedStudents.length==1){
                        this.editAssignmentSelection = Number(this.determineAssignment(this.selectedStudents[0],1).code)
                    } else {
                        this.editAssignmentSelection = null
                    }
                }
            },
        }
	}
</script>

<style lang="less" scoped>
</style>