<template>
    <UserManagmentModal
        :value="value"
        :headline="$t('classMgmt.classCodeHeader')"
        :subHeadline="$t('classMgmt.classCodeSubHeader',{className})"
        @closeDialogs="closeDialogs()"
        size="600"
    >
        <v-card-text class="pb-0">
            <p v-html="$t('classMgmt.classCodeIntro',{className,joinURL})" />
            <v-alert
                :value="!classInfo.isClassCodeActive"
                type="warning"
                outline
                >
                {{ $t('classMgmt.classCodePaused') }}
            </v-alert>
            <v-flex class="grey lighten-3 display-4 text-xs-center well">
                {{classCode}}
            </v-flex>
            <v-flex xs12 v-if="isOwner">
                <v-layout>
                    <v-flex shrink mr-2>             
                        <v-checkbox
                            v-model="disableCCBox"
                            @click="updateClass"
                            :label="$t('classCode.pauseClassCode')"
                            :disabled="loading"
                        ></v-checkbox>
                    </v-flex>
                    <v-flex align-self-center>
                        <a :href="classCodeHelpUrl" target="_blank" class="remove-underline">
                            <v-icon class="mb-1 mr-1 flex-grow" color="primary">help</v-icon>
                        </a>  
                    </v-flex>                          
                </v-layout>
            </v-flex>
        </v-card-text>
        <v-card-actions>
            <v-btn flat color="primary" :disabled="downloading" @click.prevent="downloadPDF" class="ml-auto">
                <v-icon small class="mr-2">download</v-icon>
                {{$t('classCode.handout')}}
            </v-btn>
            <v-btn flat color="primary" class="elevation-0" @click="copyCode">
                <v-icon small class="mr-2">content_paste</v-icon>
                {{$t('classMgmt.copyCode')}}
            </v-btn>
        </v-card-actions>
    </UserManagmentModal>
</template>

<script>
    import APIs from 'mixins/APIs'
    import UserManagmentModal from 'components/layout/UserManagmentModal'
    import LoginDetector from 'mixins/LoginDetector'
    import { mapGetters } from 'vuex'

	export default {
        name: 'ClassCodeModal',
        components: { UserManagmentModal },
        mixins: [APIs, LoginDetector],
        props: {
            classInfo: Object,
            institutionID: Number,
            productId: Number,
            value: Boolean,
        },
        data () {
			return {
                downloading: false, 
                loading: false    
			}
		},
		computed : {
            ...mapGetters('platformData/profile', ['profile']),
            classCode(){
                return 'EL'+this.classInfo.classCode
            },
            classCodeHelpUrl() {
                return this.getHelpCenterArticleURL('articles/8937810-pausing-gizmos-class-codes')
            },
            className(){
                return this.classInfo.name
            },
            isOwner(){
                return this.classInfo.ownerID==this.profile.id
            },
            joinURL(){
                return this.getClassCodeURL()
            },
            platform(){
                 return this.$store.state.platform
            },
        },
        methods : {
            closeDialogs() {
                this.$emit('closeDialogs',)
            },
            copyCode() {
                navigator.clipboard.writeText(this.classCode)
                this.$emit('handleSuccess', 'Code copied to clipboard.')
            },
            async downloadPDF() {
                this.downloading = true
                let header = this.jtiHeader
                header.responseType = 'arraybuffer'
                await this.$http.get(this.getClassCodeRegDoc(this.classInfo.classCode, this.className), header).then(response => {
                    const data = response.data
                    const blob = new Blob([data])
                    const link = document.createElement("a")
                    link.href = URL.createObjectURL(blob)
                    console.log(link.href);
                    link.download = `Gizmos Student Enrollment.pdf`
                    link.click()
                    URL.revokeObjectURL(link.href)
                }, error => {
					console.log(error)
				});
                this.downloading = false
            },
            setup() {
                this.disableCCBox = !this.classInfo.isClassCodeActive
            },
            async updateClass() {
                this.loading = true
                try {
                    await this.platform.updateClass({
                        id: this.classInfo.id,
                        institutionId:this.institutionID, 
                        productId: this.productId, 
                        ownerId: this.classInfo.ownerID, 
                        name: this.classInfo.name, 
                        isClassCodeEnabled: this.classInfo.isClassCodeEnabled, 
                        isClassCodeActive: !this.classInfo.isClassCodeActive
                    })
                    await this.$emit("updateModel")
                } catch (error) {
                    console.log(error)
                    await this.$emit("handleError")
                    this.setup()
                }
                this.loading = false
            }
        },
        watch: {
            classInfo(val){
                if (val){
                    this.setup()
                }
            }
        },
        created() {
            this.setup()
        }
	}
</script>

<style lang="less" scoped>
    .well {
        margin: 10px 0;
        padding: 10px 0;
        border-radius: 4px;
    }
</style>