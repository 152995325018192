import _sortBy from 'lodash/sortBy'
import Constants from 'mixins/Constants'
import ProductInfo from 'mixins/ProductInfo'
import RoleDetector from 'mixins/RoleDetector'
import TableHelper from 'mixins/TableHelper'
import { mapGetters, mapActions } from 'vuex'

export default {
    mixins: [Constants,ProductInfo,TableHelper, RoleDetector],
    data(){
        return {
            componentKey: 0, // Hack to Keep Open Modals' data fresh. Modals that can go stale (editStudent) should point their key to this.
            errorBar: false,
            errorMessage: '',
            initialCleanUp : true,
            modalParameters: {},
            productData: {
                2 : {
                    sectorMax : 0,
                    studentsSectorLimit: [] 
                }
            },
            productId: 0,
            selectedSchoolID: 0,
            successBar: false,
            successMessage: ''
        }
    },
    computed: {
        ...mapGetters('platformData/students', ['studentList']),
        ...mapGetters('platformData/teachers', ['teacherList']), 
        ...mapGetters('platformData/administrators', ['administratorList']), 
        ...mapGetters('platformData/subscriptions', ['subscriptionList']),
        ...mapGetters('platformData/profile', ['profile']),
        administrators(){
            if(this.administratorList){
                return this.administratorList.map(admin => ({
                    ...admin,
                    searchableName: admin.lastName + ' ' + admin.firstName
                }))
            } else {
                return []
            }
        },
        currentUserID(){
            return this.profile.id
        },
        gradeLevelIdFilter(){
            let options = JSON.parse(JSON.stringify(this.gradeLevelIDs))
            options.unshift({id:-1,label:'All Grade Levels'})
            return options
        },
        institutionID() {
            if(this.selectedSchoolID){
                return Number(this.selectedSchoolID)
            } else {
                return null
            }
        },
        institutionListByID() {
            return this.institutionList.sort((a, b) => a.id - b.id)
        },
        isSubscriptionRostered(){
            let isRostered = false
            this.subscriptionList.forEach(sub =>{
                if(sub.settings.isRosterEnabled){
                    isRostered = true
                }
            })
            return isRostered
        },
        isSubscriptionSSO(){
            if(this.subscriptionList.length){
                let sub = this.subscriptionList[0]
                let settings = sub.settings
                return settings.isSsoLtiEnabled || settings.isSsoSamlEnabled || settings.isSsoCleverEnabled || this.isSubscriptionRostered
            } else {
                return false
            }
        },
        isAdmin(){
            return this.$store.getters["platformData/profile/isAdmin"]
        },
        isTeacher() {
            return this.profile.roleID == 3
        },
        multiProduct(){
            if(this.ownedProducts){
                return this.ownedProducts.length>1
            } else {
                return false
            }
        },
        nonStudents(){
            if(this.administrators && this.teachers){
                return this.administrators.concat(this.teachers)
            } else {
                return []
            }
        },
        ownedProducts(){
            let products = []
            if(this.isEmployeeOrCertifiedTrainer && this.useEmployeeData){
                this.productDisplayOrder.forEach(prodID => {
                    if(this.employeeData.products.includes(prodID)){
                        products.push(prodID)
                    }
                })
            } else {
                this.productDisplayOrder.forEach(prodID => {
                    if(this.hasProductSubscription(this.subscriptions,prodID)){
                        products.push(prodID)
                    }
                })
            }
            if (this.isClassCodeEnabledSchool && this.currentEnvironment != 'el' && !this.isAdmin) {
                products = products.filter(product => {
                    if(this.currentEnvironment == 'gizmos') {
                        return product == 3
                    } else {
                        return product != 3
                    }
                })
            }
            return products
        },
        passedOrSelectedUsers(){
            if (this.modalParameters.users) {
                if (Array.isArray(this.modalParameters.users)) {
                    return this.modalParameters.users
                }
                else {
                    return [this.modalParameters.users]
                }
            }
            else if (this.selectedStudents && this.selectedStudents.length>0) {
                return this.selectedStudents
            }
            else if (this.selectedNonStudents && this.selectedNonStudents.length>0) {
                return this.selectedNonStudents
            }
            else {
                return [{}]
            }
        },
        platform(){
            return this.$store.state.platform
        },
        productAccessColumnMinWidth(){
            return 'min-width:'+(this.ownedProducts.length*30+32)+'px;'
            // 30 = leaf width of 25 + margin left of 5
            // 32 = cell left padding of 16 + cell right padding of 16
        },
        isClassCodeEnabledSchool(){
            return this.selectedSchool ? this.selectedSchool.isClassCodeEnabled && !this.isSubscriptionSSO : false
        },
        selectedSchool(){
            let schoolList = this.institutionList         
            if(schoolList){
                let school = schoolList.find(school => {
                    return this.selectedSchoolID==school.id
                })
                return school || schoolList[0]
            } else {
                return {}
            }
        },
        students() {
            let correctStudents = []
            if(this.isClassCodeEnabledSchool && !this.isAdmin) {
                if (this.currentEnvironment == 'el') {
                    correctStudents = this.studentList
                } else if (this.currentEnvironment == 'gizmos') {
                    correctStudents = this.classCodeEnabledStudentList
                } else {
                    correctStudents = this.nonClassCodeEnabledStudentList
                }
            } else if (this.isClassCodeEnabledSchool && this.isAdmin) {
                if (this.selectedSchool.subscriptions.some(sub => sub.productId != 3)){
                    correctStudents = this.nonClassCodeEnabledStudentList
                } else {
                    correctStudents = this.classCodeEnabledStudentList
                }
            } else {
                correctStudents = this.studentList
            }

            correctStudents.forEach(student => {
                this.ownedProducts.forEach(productID=>{
                    student.assignments = {}
                    let productName = this.productDisplayName(productID)
                    student.assignments[productName.toLowerCase()] = this.determineAssignment(student,productID)
                    student['assignment'+productName] = this.determineAssignment(student,productID).short
                })
                student.searchableName = student.lastName + ' ' + student.firstName
            })
            
            return correctStudents
        },
        subscriptions() {
            if (this.subscriptionList) {
                const productExclusionList = process.env.productExclusionList
                //create activeSubs which filters out expired and licenses that are for products on the exclusion list
                let activeSubs = this.subscriptionList.filter(sub=>{
                    let isActive = sub.isActive && !productExclusionList.includes(sub.productId)
                    if(!this.licenseTypeIsExpiredExempted(sub.productId, sub.licensingTypeId)){
                        isActive = isActive && !sub.isExpired
                    }
                    return isActive
                })
                //optOuts includes and productIDs the user has opted out of seeing
                let optOuts = this.profile.productsOptOut
                //filters out any subs that are part of an opted out product
                if(optOuts.length){
                    let optedInProducts = []
                    activeSubs.forEach(sub => {
                        if(!optOuts.includes(sub.productId)){
                            optedInProducts.push(sub)
                        }
                    })
                    activeSubs = optedInProducts
                }
                //add productName key/value pair to each active sub
                activeSubs.forEach(sub => {
                    sub.productName = this.productDisplayName(sub.productId)
                })
                //if there is a selected school and the selected school's ID is for a public, private or home school continue into "if" logic
                const terminalSchoolTypeIDs = [
                    this.institutionTypeIDs.PUBLIC_SCHOOL,
                    this.institutionTypeIDs.PRIVATE_SCHOOL,
                    this.institutionTypeIDs.HOME
                ]
                //filter out any subs that do not belong to a product that is associated to the selected school
                if(this.selectedSchool && terminalSchoolTypeIDs.includes(this.selectedSchool.typeId)){
                    let products=[]
                    this.selectedSchool.products.forEach(product=>{
                        products.push(product.productId)
                    })
                    return activeSubs.filter(sub=>{
                        return products.includes(sub.productId)
                    })
                } else {
                    return activeSubs
                }
            } else {
                return []
            }
        },
        teachers() {
            return this.teacherList.map(teacher => ({
                ...teacher,
                searchableName: teacher.lastName + ' ' + teacher.firstName
            }))
        },
        studentsWithAssignments() {
            this.students.sort((a,b) => {
                const nameA = `${a.lastName.toUpperCase()}${a.firstName.toUpperCase()}`;
                const nameB = `${b.lastName.toUpperCase()}${b.firstName.toUpperCase()}`;
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
            })

            return this.students.map(student => {
                let assignments = {}
                this.ownedProducts.forEach(productID=>{
                    let productName = this.productDisplayName(productID)
                    assignments[productName.toLowerCase()] = this.determineAssignment(student,productID)
                })
                return { 
                    ...student, 
                    assignments
                }
            })
        }
    },
    methods: {
        ...mapActions('platformData/app', ['initApp', 'fetchModel']),
        canStudentLogIn(student){
            if(this.isEmployeeOrCertifiedTrainer && this.useEmployeeData){
                if(this.employeeData.subscriptionMap[student.id]){
                    return Boolean(this.employeeData.subscriptionMap[student.id].length)
                } else {
                    return false
                }
            } else {
                if (student.licenses != null) {
                    return Boolean(this.getMatchingLicenses(student).length)
                } else {
                    return false
                }
            }
        },
        changeSort (column, paginationOptions) {
            if (paginationOptions.sortBy === column) {
                paginationOptions.descending = !paginationOptions.descending
            } else {
                paginationOptions.sortBy = column
                paginationOptions.descending = false
            }
        },
        checkProductUpgrade(productId, student){
            if(student.roleID == this.roleIDs.STUDENT && productId == 2){
                let studentFraxData = this.productData[2].studentsSectorLimit.find(studentData=>{
                    return studentData.userID == student.id
                })
                if(studentFraxData){
                    let hasFinishedSector = studentFraxData.maxSectorID == studentFraxData.maxSectorCompleted
                    let moreSectorsAvailiable = studentFraxData.maxSectorCompleted < this.productData[2].sectorMax 
                    return hasFinishedSector && moreSectorsAvailiable
                } else {
                    return false
                }
            } else {
                return false
            }
        },
        // No Longer Used
        cleanUpStudents(studentsToClean){
            let students = studentsToClean || this.students
            students.forEach(student => {
                student.name = student.lastName+', '+student.firstName
                student.assignments = {}
                this.ownedProducts.forEach(productID=>{
                    let productName = this.productDisplayName(productID)
                    student.assignments[productName.toLowerCase()] = this.determineAssignment(student,productID)
                    student['assignment'+productName] = this.determineAssignment(student,productID).short
                })
                student.grade = this.getGradeLevel(student.gradeId)
                student.language = this.getLanguage(student.languageId)
                student.password = student.passwordClear
            })
        },
        closeDialogs(modalsToClose){
            if (modalsToClose) {
                modalsToClose.forEach( modal => { this.modalControl[modal] = false })
            } else {
                for(let modal in this.modalControl) {
                    this.modalControl[modal] = false
                }
            }
            this.$store.commit('setMessageError', null)
            this.$store.commit('setMessageSuccess', null)
            this.$store.commit('setMessageInfo', null)
        },
        containsRosteredUser(users){
            let containsRosteredUser = false
            users.forEach(user => {
                if(user.isRostered){
                    containsRosteredUser = true
                }
            })
            return containsRosteredUser
        },
        async deleteUsers(users){
            let institutionId = this.institutionID
            let userIds = this.getIDs(users)
            let self = this;
            (async function() {
                await self.platform.deleteUsers({institutionId,userIds})
                if(self.activeOrPending && self.activeOrPending=='pending'){
                    await self.updateInvites()
                }
                await self.updateModel()
                self.closeDialogs()
                self.selectedStudents = []
                if(self.selectedNonStudents){
                    self.selectedNonStudents = []
                }
            })()
        },
        determineAccess(student,productCode){
            let hasLicense = false
            if(this.isEmployeeOrCertifiedTrainer && this.useEmployeeData){
                if(this.employeeData.subscriptionMap[student.id]){
                    this.employeeData.subscriptionMap[student.id].forEach(sub => {
                        if(sub.productID==productCode){
                            hasLicense = true
                        }
                    })
                }
            } else {
                if(Boolean(this.getMatchingLicenses(student))){
                    hasLicense = this.getMatchingLicenses(student).includes(productCode)
                }
            }
            return this.getProductAccess(hasLicense)

        },
        determineAssignment(student,productCode){
            if(student.roleID!=this.roleIDs.STUDENT || this.isEmployeeOrCertifiedTrainer || this.productSettings[productCode].assignmentType=='access'){
                return this.determineAccess(student,productCode)
            }
            let assignment = {
                code : 0,
                short : this.getAssignmentShort(0),
                label : this.getAssignmentLabel(0)
            }
            if(!this.getMatchingLicenses(student) || !this.getMatchingLicenses(student).includes(productCode)){
                return assignment
            } else {
                if(productCode==1){
                    let parsedCode = student.reflexJson.AssignmentID
                    if(parsedCode != "null"){
                        assignment.code = Number(parsedCode)
                    }
                    assignment.short = this.getAssignmentShort(assignment.code)
                    assignment.label = this.getAssignmentLabel(assignment.code)
                } else if(productCode==2){
                    assignment.code = 0
                    if(this.productData[2] && this.productData[2].studentsSectorLimit && this.productData[2].studentsSectorLimit.length){
                        let sectorLimit = this.productData[2].studentsSectorLimit.find(studentLimit=>{
                            return studentLimit.userID == student.id
                        })
                        if(sectorLimit){
                            assignment.code = sectorLimit.maxSectorID
                        }
                    }
                    assignment.short = this.getSectorShort(assignment.code)
                    assignment.label = this.getSectorLabel(assignment.code)
                }
                return assignment
            }
        },
        doNothing(){
            // Nothing Done
        },
        enterStudentEditMode(student){
            this.editedStudentInfo.firstName = student.firstName
            this.editedStudentInfo.middleName = student.middleName
            this.editedStudentInfo.lastName = student.lastName
            this.editedStudentInfo.gradeId = student.gradeId
            this.editedStudentInfo.sisUserId = student.sisUserId
            this.editedStudentInfo.userName = student.username
            this.editedStudentInfo.passwordClear = student.passwordClear
            this.editedStudentInfo.languageId = student.languageId
            this.editedStudentInfo.specialEdId = student.specialEdId
            this.editedStudentInfo.engProfId = student.engProfId
            this.editedStudentInfo.genderId = student.genderId
            this.editedStudentInfo.ethnicityId = student.ethnicityId
            this.editedStudentInfo.disadvId = student.disadvId
            this.editStudentModalEditMode = true
        },
        getIDs(users){
            let IDs = []
            if(Array.isArray(users)){
                users.forEach(function(user){
                    let id = user.id || user.login.id
                    IDs.push(id)
                })
            } else {
                let id = user.id || user.login.id
                IDs.push(id)
            }
            return IDs
        },
        getMatchingLicenses(user){
            if(user.licenses) {
                return user.licenses.filter(l => l.matchingLicense).map(l => l.productId)
            } else {
                return []
            }
        },
        getProductData(){
            this.subscriptions.forEach(sub=>{
                switch(sub.productId){
                    case 2: 
                        this.getProductDataFrax()
                        break
                }
            })
        },
        async getProductDataFrax(){
            let fraxData = {}
            const studentsWithFrax = this.students.filter(student => {
                return this.getMatchingLicenses(student).includes(2)
            })
            const studentIDs = (this.getIDs(studentsWithFrax))
            await this.$http.post(this.getStudentInfoFraxAPI(), studentIDs, this.jtiHeader).then(response => {
                fraxData.studentsSectorLimit = response.data.ArrayList
            }, error => {
                console.log(error)
            })
            const fraxSub = this.getProductSubscription(this.subscriptions,2)
            await this.$http.get(this.getSectorLimitAPI(fraxSub.id),this.jtiHeader).then(response => {
                fraxData.sectorMax = response.data.Short
                if(fraxData.sectorMax < 0){
                    fraxData.sectorMax = this.sectorIDs.length-1
                }
            }, error => {
                console.log(error)
            })
            this.productData[2] = fraxData
        },
        getProductSubscription(subscriptions,prodID){
            let productSub = null
            subscriptions.forEach(sub => {
                if(sub.id){
                    let subProdId = sub.productId
                    if(subProdId == prodID){
                        productSub = sub
                    }
                }
            })
            return productSub
        },
        handleError(message){
            this.errorMessage = message
            this.errorBar = true
        },
        handleSuccess(message){
            this.successMessage = message
            this.successBar = true
        },
        hasGradeLevelLessThan(students,gradeLevelID){
            let lessThan = false
            students.forEach(student => {
                if(student.gradeID<gradeLevelID){
                    lessThan = true
                }
            })
            return lessThan
        },
        hasProductSubscription(subscriptions,prodID){
            return Boolean(this.getProductSubscription(subscriptions,prodID))
        },
        openDialog(dialogName, parameters={}, closeOtherModals=true){
            if (closeOtherModals){
                this.closeDialogs()
            }
            if (dialogName.includes('Modal')){
                this.modalControl[dialogName] = true
            }
            this.modalParameters = parameters
        },
        openNewStudentDialog() {
            this.$emit('openDialog', 'addStudentNewModal')
        },
        printLocation(location){
            let locationString = ''
            if(location.city){
                locationString = locationString + location.city
            }
            if(location.city && location.state){
                locationString = locationString + ', '
            }
            if(location.state){
                locationString = locationString + location.state
            }
            return locationString
        },
        rowsPerPageItems(tableCount){
            if(tableCount=="modal"){
                return [5,10,25]
            }
            let options = [10,25]
            if(tableCount>100){
                options.push(100)
            }
            if(tableCount>500){
                options.push(500)
            } else {
                options.push({"text":"$vuetify.dataIterator.rowsPerPageAll","value":500})
            }
            return options
        },
        seatInfo(productID){
            if(this.selectedSchool && this.selectedSchool.products){
                let product = this.selectedSchool.products.find(product => {
                    return product.productId == productID
                })
                if(product){
                    if(product.studentsCap < 0){
                        product.studentsCap = Number.POSITIVE_INFINITY
                    }
                    product.studentDelta = product.studentsCap - product.studentsUsed
                    if(product.teachersCap < 0){
                        product.teachersCap = Number.POSITIVE_INFINITY
                    }
                    product.teacherDelta = product.teachersCap - product.teachersUsed
                    product.name = this.productDisplayName(product.id)
                    return product
                } else {
                    return {}
                }
            } else {
                return {}
            }
        },
        subSeatInfo(subID) {
            if(this.selectedSchool && this.selectedSchool.products){
                let sub = this.selectedSchool.products.find(product => {
                    return product.subscriptionId == subID
                })
                if(sub){
                    if(sub.studentsCap < 0){
                        sub.studentsCap = Number.POSITIVE_INFINITY
                    }
                    sub.studentDelta = sub.studentsCap - sub.studentsUsed
                    if(sub.teachersCap < 0){
                        sub.teachersCap = Number.POSITIVE_INFINITY
                    }
                    sub.teacherDelta = sub.teachersCap - sub.teachersUsed
                    sub.name = this.productDisplayName(sub.productId)
                    return sub
                } else {
                    return {}
                }
            } else {
                return {}
            }
        },
        trackAddStudents(method, source, numNewStudents){
            let eventName = this.isTeacher ? 'students_added_to_class' : 'students_added_to_school'
            let numCurrentStudent = this.isTeacher ? this.selectedClass.studentIDs.length : this.students.length
            let props = {
				method : method,
                source : source,
				number_students_added : numNewStudents,
			    number_students_total : this.isTeacher ? numCurrentStudent + numNewStudents : numCurrentStudent,
                school_name : this.selectedSchool.name,
				school_id : this.selectedSchool.id,
                school_pid : this.selectedSchool.pid
			}
            if(this.isTeacher){
                props.class_name = this.selectedClass.name
				props.class_id = this.selectedClass.id
            }
            pendo.track(eventName, props)
        },
        async updateModel(institutionId){
            this.loading = true
            let self = this;
            let iID = (institutionId) ? institutionId : this.institutionID;
            await (async function() {
                await self.fetchModel({
                    institutionId: iID,
                    subscriptionId: self.subscriptionId,
                    isEmployee : self.isEmployeeOrCertifiedTrainer
                })
                await self.getProductData()
                if (self.modalParameters.users && self.passedOrSelectedUsers.length===1) {
                    let user = self.passedOrSelectedUsers[0]
                    const { administrators, teachers, students } = self.$store.state.platformData;
                    const updatedUser = administrators.administrators[user.id] || teachers.teachers[user.id] || students.students[user.id]                    
                    if (updatedUser) {
                        self.modalParameters.users = updatedUser
                    }
                }
                if(self.allStudents){
                    self.allStudents = await self.platform.fetchStudentsByInstitution({institutionId:iID})
                }
                if(self.isEmployeeOrCertifiedTrainer && self.useEmployeeData){
                    self.getAlternativeSubscriptionInformationInfo(self.selectedSchoolID)
                }
                // Hack to Keep Open Modals' data fresh. See Note in Data
                self.componentKey++
            })()
            window.postMessage({institutionId:iID})
            this.loading = false
        },
        async updateSectors(students,sectorID,maintainDialogs){
            this.loading = true
            let toAddToProduct = []
            let toRemoveFromProduct = []
            students.forEach(function(student){
                if(sectorID){
                    toAddToProduct.push(student.id)
                } else {
                    toRemoveFromProduct.push(student.id)
                }
            })
            let institutionId = this.institutionID
            let tenantId = this.tenantID
            const subscriptionIdsToRemove = [];
            students.forEach(student => {
                if (student.licenses && student.licenses.length) {
                    student.licenses.forEach(license => {
                        if (license.productId === 2 && license.matchingLicense) {
                        // Check if subscription ID already exists, only add if its unique
                        if (!subscriptionIdsToRemove.some(id => id === license.subscriptionId)) {
                                subscriptionIdsToRemove.push(license.subscriptionId);
                            }
                        }
                    });
                }
            });
            let subscriptionIdsToAdd = [this.selectedSchool.subscriptions.find(sub => sub.productId === 2).id]
            if(toRemoveFromProduct.length){
                await this.platform.removeUsersFromSubscription({tenantId, institutionId, subscriptionIds: subscriptionIdsToRemove, loginIds: toRemoveFromProduct})
            }
            if(toAddToProduct.length){
                await this.platform.addUsersToSubscription({tenantId, institutionId, subscriptionIds: subscriptionIdsToAdd, loginIds: toAddToProduct})
                await this.$http.post(this.getUpdateSectorAPI(sectorID),toAddToProduct,this.jtiHeader).then(response => {
                    // Do Nothing
                }, error => {
                    console.log(error)
                })
            }
            await this.updateModel()
            if(!maintainDialogs){
                this.closeDialogs()
            } else {
                this.closeDialogs(['editSectorModal'])
            }
            this.loading = false
        },
        userCountOrName(users, descriptor, ignoreDescriptor){
            if(users.length>1){
                return users.length + ' ' + descriptor
            } else if(users.length==1) {
                return (ignoreDescriptor ? '' : descriptor + ': ') + users[0].firstName + ' ' + users[0].lastName
            } else {
                return ''
            }
        },
    }
}
